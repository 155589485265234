import { MarketCountryCode } from '@backmarket/http-api'
import { type I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import translations from '../Footer.translations'
import type { FooterContent } from '../FooterContent'

import { getCCPALink, getCookiePolicyLink, getFooterLink } from './FooterLinks'

const INSTAGRAM_URL = 'https://www.instagram.com/'
const FACEBOOK_URL = 'https://www.facebook.com/'
const TWITTER_URL = 'https://www.twitter.com/'

export type FooterCategory = {
  title?: I18nDefinition
  content: FooterContent[]
}

export function useFooterCategories(): FooterCategory[] | null {
  const { market } = useMarketplace()

  switch (market.countryCode) {
    case MarketCountryCode.AT:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketat`,
                instagram: `${INSTAGRAM_URL}backmarket_at`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.at/de-at/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/de-at/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market-',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/de-at/articles/360026646054-In-welches-Land-kann-ich-liefern-lassen-und-wie-viel-kostet-mich-das-',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/de-at/categories/360002106194-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-Refunds--Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
            getFooterLink('BLOG', 'https://story.backmarket.at'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/de-at/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'GLASSDOOR',
              'https://www.glassdoor.at/%C3%9Cberblick/Arbeit-bei-Back-Market-EI_IE1780208.11,22.htm?countryRedirect=true',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://de.wikipedia.org/wiki/DJ_%C3%96tzi',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp', 'customer-service-of-the-year'],
            },
          ],
        },
      ]
    case MarketCountryCode.AU:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}backmarket`,
                linkedin: 'https://linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketaus`,
                instagram: `${INSTAGRAM_URL}backmarket`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.com.au/en-au/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/en-au/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/en-au/articles/360033250174',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/en-au/categories/360002106194-Returns-refunds',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_SERVICE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/en-au/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://fr.trustpilot.com/review/backmarket.com.au',
            ),
            getFooterLink(
              'GLASSDOOR',
              'https://www.glassdoor.com.au/Overview/Working-at-Back-Market-EI_IE1780208.11,22.htm',
            ),
            getFooterLink('MEDIUM'),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.BE:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRO', 'https://pro.backmarket.fr'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market_be`,
                linkedin: 'https://be.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}pages/Back-Market/1497704560478521`,
                instagram: `${INSTAGRAM_URL}backmarket_fr`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.be/fr-be/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/fr-be/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/fr-be/articles/360026646054-Dans-quel-pays-puis-je-me-faire-livrer-et-combien-cela-me-co%C3%BBte',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/fr-be/categories/360002106194-Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('INSURANCES'),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
            getFooterLink('BLOG', 'https://story.backmarket.fr'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/fr-be/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://fr.trustpilot.com/review/backmarket.be',
            ),
            getFooterLink(
              'GLASSDOOR',
              'https://fr.glassdoor.be/Pr%C3%A9sentation/Travailler-chez-Back-Market-EI_IE1780208.16,27.htm',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://fr.wikipedia.org/wiki/Nana_Mouskouri',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.DE:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink(
              'TRUST_PILOT',
              'https://de.trustpilot.com/review/www.backmarket.de',
            ),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market_de`,
                linkedin: 'https://de.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketdeutschland`,
                instagram: `${INSTAGRAM_URL}backmarket_de`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'CONTACT_US',
              'https://help.backmarket.com/hc/de/articles/360026654654-Wie-kontaktiere-ich-den-Kundenservice-von-Back-Market',
            ),
            getFooterLink('HELP'),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/de/articles/360026646054-In-welches-Land-kann-ich-liefern-lassen-und-wie-viel-kostet-mich-das',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/de/categories/360002106194-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
            ),
          ],
        },
        {
          title: translations.categoryServices,
          content: [
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('INSURANCES'),
            getFooterLink('BUY_BACK'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            // getFooterLink('REFER_FRIEND'), // Not live yet
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.de/de-de/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks-with-link',
              url: 'https://help.backmarket.com/hc/de/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market',
            },
          ],
        },
        {
          title: translations.categoryGuides,
          content: [
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('TERMS_BUYBACK'),
            getCookiePolicyLink(),
            getFooterLink('DATA_PROTECTION'),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/de/requests/new?ticket_form_id=12226993197084',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.ES:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('PRESS', '/impact/es-es/newsroom'),
            getFooterLink('IMPACT', '/impact/es-es'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink(
              'TRUST_PILOT',
              'https://es.trustpilot.com/review/www.backmarket.es',
            ),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market_es`,
                linkedin: 'https://es.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}Back-Market-España-196051144097157`,
                instagram: `${INSTAGRAM_URL}backmarket_es`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'CONTACT_US',
              'https://help.backmarket.com/hc/es/articles/360026654654--C%C3%B3mo-contacto-con-la-Atenci%C3%B3n-al-cliente-de-Back-Market',
            ),
            getFooterLink('HELP'),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/es/articles/360026646054--En-qu%C3%A9-pa%C3%ADs-me-pueden-entregar-y-cu%C3%A1nto-me-cuesta',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/es/categories/360002106194-Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
          ],
        },
        {
          title: translations.categoryServices,
          content: [
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('INSURANCES'),
            getFooterLink('BUY_BACK'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            // getFooterLink('REFER_FRIEND'), // Not live yet
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.es/es-es/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks-with-link',
              url: 'https://help.backmarket.com/hc/es/articles/360026643854--Qu%C3%A9-m%C3%A9todos-de-pago-se-aceptan-en-Back-Market',
            },
          ],
        },
        {
          title: translations.categoryGuides,
          content: [
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('TERMS_BUYBACK'),
            getCookiePolicyLink(),
            getFooterLink('DATA_PROTECTION'),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/es/requests/new?ticket_form_id=12226993197084',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.FI:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketfi`,
                instagram: `${INSTAGRAM_URL}backmarket_fi`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.fi/fi-fi/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/fi/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/fi/articles/360026646054',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/fi/categories/360002106194-Palautukset-ja-hyvitykset-Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('BLOG', 'https://story.backmarket.fi'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/fi/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://fi.trustpilot.com/review/backmarket.fi?languages=all',
            ),
            getFooterLink(
              'GLASSDOOR',
              'https://www.glassdoor.com/Reviews/Back-Market-Reviews-E1780208_P3.html',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://fi.wikipedia.org/wiki/M%C3%A4mmi',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.FR:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('PRESS', '/impact/fr-fr/actualites'),
            getFooterLink('IMPACT', '/impact/fr-fr'),
            getFooterLink(
              'TRUST_PILOT',
              'https://fr.trustpilot.com/review/www.backmarket.fr',
            ),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market`,
                linkedin: 'https://fr.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}pages/Back-Market/1497704560478521`,
                instagram: `${INSTAGRAM_URL}backmarket_fr/`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'CONTACT_US',
              'https://help.backmarket.com/hc/fr-fr/articles/360026654654-Comment-puis-je-contacter-le-service-client%C3%A8le-de-Back-Market',
            ),
            getFooterLink('HELP'),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/fr-fr/articles/360026646054-Dans-quel-pays-puis-je-me-faire-livrer-et-combien-cela-me-co%C3%BBte',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/fr-fr/categories/360002106194-Retours-Remboursements',
            ),
          ],
        },
        {
          title: translations.categoryServices,
          content: [
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('INSURANCES'),
            getFooterLink('BUY_BACK'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            // getFooterLink('REFER_FRIEND'), // Not live yet
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.fr/fr-fr/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink('PRO', 'https://pro.backmarket.fr'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks-with-link',
              url: 'https://help.backmarket.com/hc/fr-fr/articles/360026643854-Quels-sont-les-moyens-de-paiement-accept%C3%A9s-sur-Back-Market',
            },
          ],
        },
        {
          title: translations.categoryGuides,
          content: [
            getFooterLink('BACK_STORIES'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('TERMS_BUYBACK'),
            getCookiePolicyLink(),
            getFooterLink('DATA_PROTECTION'),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/fr-fr/requests/new?ticket_form_id=12226993197084',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp', 'best-e-commerce-website'],
            },
          ],
        },
      ]
    case MarketCountryCode.GB:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('PRESS', '/impact/en-gb/newsroom'),
            getFooterLink('IMPACT', '/impact/en-gb'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink(
              'TRUST_PILOT',
              'https://www.trustpilot.com/review/www.backmarket.co.uk',
            ),
            {
              type: 'social-networks',
              networks: {
                linkedin: 'https://linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketuk`,
                instagram: `${INSTAGRAM_URL}backmarket_uk`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'CONTACT_US',
              'https://help.backmarket.com/hc/en-gb/articles/360026654654-How-do-I-contact-Back-Market-Customer-Care',
            ),
            getFooterLink('HELP'),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/en-gb/articles/360026646054-Where-can-I-get-delivered-and-how-much-does-it-cost-me',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/en-gb/categories/360002106194-Returns-refunds--Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
          ],
        },
        {
          title: translations.categoryServices,
          content: [
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('INSURANCES'),
            getFooterLink('BUY_BACK'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            // getFooterLink('REFER_FRIEND'), // Not live yet
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.co.uk/en-gb/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks-with-link',
              url: 'https://help.backmarket.com/hc/en-gb/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market',
            },
          ],
        },
        {
          title: translations.categoryGuides,
          content: [
            getFooterLink('BACK_STORIES'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('TERMS_BUYBACK'),
            getCookiePolicyLink(),
            getFooterLink('DATA_PROTECTION'),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/en-gb/requests/new?ticket_form_id=12226993197084',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.GR:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market`,
                linkedin: 'https://fr.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketgr`,
                instagram: `${INSTAGRAM_URL}backmarket_gr/`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.gr/el-gr/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/el/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/el/articles/360034275533',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/el/categories/360002106194-%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            // TODO reactivate when blog is ready
            // getFooterLink('BLOG', 'https://story.backmarket.gr'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/el-gr/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://gr.trustpilot.com/review/backmarket.gr',
            ),
            getFooterLink(
              'CELEBRITY',
              'https://el.wikipedia.org/wiki/%CE%9D%CF%84%CE%AD%CE%BC%CE%B7%CF%82_%CE%A1%CE%BF%CF%8D%CF%83%CF%83%CE%BF%CF%82',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.IE:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketie`,
                instagram: `${INSTAGRAM_URL}backmarket_ie`,
                linkedin: 'https://linkedin.com/company/back-market',
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.ie/en-ie/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/en-ie/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market-',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/en-ie/articles/360026646054-Where-can-I-get-delivered-and-how-much-does-it-cost-me-',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/en-ie/categories/360002106194-Returns-refunds-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            // TODO reactivate when blog is ready
            // getFooterLink('BLOG', 'https://story.backmarket.ie'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/en-ie/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            // TODO: Missing link for trust pilot: getFooterLink('TRUST_PILOT', )
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://en.wikipedia.org/wiki/Dustin_the_Turkey',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.IT:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}back_market_it`,
                linkedin: 'https://it.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketitalia`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.it/it-it/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/it/articles/360026643854-Quali-sono-i-metodi-di-pagamento-accettati-da-Back-Market',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/it/articles/360026646054-In-quale-paese-posso-essere-consegnato-e-quanto-costa',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/it/categories/360002106194-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('INSURANCES'),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('BLOG', 'https://story.backmarket.it'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/it/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUSTED_SHOPS',
              'https://it.trustpilot.com/review/backmarket.it',
            ),
            getFooterLink(
              'GLASSDOOR',
              'https://www.glassdoor.it/Panoramica/Lavorando-in-Back-Market-EI_IE1780208.13,24.htm',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://it.wikipedia.org/wiki/Raffaella_Carr%C3%A0',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.JP:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', '/ja-jp/e/press'),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}backmarket_jp`,
                linkedin: 'https://jp.linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}backmarketjapan`,
                instagram: `${INSTAGRAM_URL}backmarket_jp`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.co.jp/ja-jp/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/ja/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/ja/articles/360033250174',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/ja/categories/360002106194-%E8%BF%94%E5%93%81-%E8%BF%94%E9%87%91%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('LEGAL'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [getFooterLink('MEDIUM')],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.NL:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketnl`,
                instagram: `${INSTAGRAM_URL}backmarket_nl`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.nl/nl-nl/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/nl/articles/360026643854-Hoe-kan-ik-op-Back-Market-betalen-',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/nl/articles/360033250174-Wanneer-wordt-mijn-bestelling-bezorgd-',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/nl/categories/360002106194-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('INSURANCES'),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('BLOG', 'https://story.thebackmarket.nl'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/nl/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://www.trustpilot.com/review/www.backmarket.nl',
            ),
            getFooterLink(
              'GLASSDOOR',
              'https://www.glassdoor.nl/Overzicht/Werken-bij-Back-Market-EI_IE1780208.11,22.htm',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://nl.wikipedia.org/wiki/K3_(muziekgroep)',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.PT:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketpt`,
                instagram: `${INSTAGRAM_URL}backmarket_pt/`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.pt/pt-pt/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/pt/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/pt/articles/360026646054',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/pt/categories/360002106194-Devolu%C3%A7%C3%B5es-Reembolsos-Returns-refunds-R%C3%BCckgabe-R%C3%BCckerstattung-Returns-refunds',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('BLOG', 'https://story.backmarket.pt'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/pt/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://pt.trustpilot.com/review/backmarket.pt?languages=all',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://fr.wikipedia.org/wiki/Am%C3%A1lia_Rodrigues',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.SE:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketse`,
                instagram: `${INSTAGRAM_URL}backmarket_se`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.se/sv-se/seller/home ',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/sv/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/sv/articles/360034275533',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/sv/categories/360002106194-Returns-Refunds--Devoluciones-y-reembolsos--%CE%95%CF%80%CE%B9%CF%83%CF%84%CF%81%CE%BF%CF%86%CE%AD%CF%82-%CE%91%CF%80%CE%BF%CE%B6%CE%B7%CE%BC%CE%B9%CF%8E%CF%83%CE%B5%CE%B9%CF%82-Reso-del-prodotto-e-rimborsi-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            // TODO reactivate when blog is ready
            // getFooterLink('BLOG', 'https://story.backmarket.se'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/sv/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink(
              'TRUST_PILOT',
              'https://www.trustpilot.com/review/backmarket.se',
            ),
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://sv.wikipedia.org/wiki/Zlatan_Ibrahimovi%C4%87',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.SK:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink('PRESS', 'mailto:press@backmarket.com'),
            {
              type: 'social-networks',
              networks: {
                facebook: `${FACEBOOK_URL}backmarketsk`,
                instagram: `${INSTAGRAM_URL}backmarket_sk`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.sk/sk-sk/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink(
              'PAYMENT',
              'https://help.backmarket.com/hc/sk/articles/360026643854',
            ),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/sk/articles/360026646054',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/sk/categories/360002106194-Vr%C3%A1tenie-objedn%C3%A1vky-a-pe%C5%88az%C3%AD-Returns-Refunds--Retours-Remboursements-Retouren-en-terugbetalingen-Returns-refunds-',
            ),
            getFooterLink('HELP'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            // TODO reactivate when blog is ready
            // getFooterLink('BLOG', 'https://story.backmarket.sk'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_USE'),
            getFooterLink('TERMS_SALE'),
            getFooterLink('DATA_PROTECTION'),
            getCookiePolicyLink(),
            getFooterLink('INFOS_CONSUMER'),
            getFooterLink('LEGAL'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/sk/requests/new?ticket_form_id=12226993197084',
            ),
            {
              key: translations.paymentMethods,
              type: 'payment-networks',
            },
          ],
        },
        {
          title: translations.categoryHello,
          content: [
            getFooterLink('MEDIUM'),
            getFooterLink(
              'CELEBRITY',
              'https://sk.wikipedia.org/wiki/Peter_Sagan',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp'],
            },
          ],
        },
      ]
    case MarketCountryCode.US:
      return [
        {
          title: translations.categoryAbout,
          content: [
            getFooterLink('ABOUT_US'),
            getFooterLink('PRESS', '/impact/en-us/newsroom'),
            getFooterLink('IMPACT', '/impact/en-us'),
            getFooterLink('ACCESSIBILITY'),
            getFooterLink('HIRE', 'https://jobs.backmarket.com'),
            getFooterLink(
              'TRUST_PILOT',
              'https://www.trustpilot.com/review/www.backmarket.com',
            ),
            {
              type: 'social-networks',
              networks: {
                twitter: `${TWITTER_URL}backmarket`,
                linkedin: 'https://linkedin.com/company/back-market',
                facebook: `${FACEBOOK_URL}BackMarketCom`,
                instagram: `${INSTAGRAM_URL}backmarket`,
              },
            },
          ],
        },
        {
          title: translations.categoryHelp,
          content: [
            getFooterLink(
              'CONTACT_US',
              'https://help.backmarket.com/hc/en-us/articles/360026654654-How-do-I-contact-Back-Market-Customer-Care',
            ),
            getFooterLink('HELP'),
            getFooterLink(
              'SHIPPING',
              'https://help.backmarket.com/hc/en-us/articles/360033250174-When-should-I-expect-my-order-to-arrive',
            ),
            getFooterLink(
              'RETURN',
              'https://help.backmarket.com/hc/en-us/categories/360002106194-Returns-refunds-',
            ),
          ],
        },
        {
          title: translations.categoryServices,
          content: [
            getFooterLink('BACKMARKET_WARRANTY'),
            getFooterLink('INSURANCES'),
            getFooterLink('BUY_BACK'),
            getFooterLink('STUDENTS_GOOD_DEALS'),
            getFooterLink('MILITARY_PROGRAM'),
            // getFooterLink('REFER_FRIEND'), // Not live yet
            getFooterLink(
              'BECOME_REFURBISHER',
              'https://backmarket.com/en-us/seller/home',
            ),
            getFooterLink('BO_MERCHANT'),
            getFooterLink('PRO', 'https://pro.backmarket.com'),
            {
              key: translations.paymentMethods,
              type: 'payment-networks-with-link',
              url: 'https://help.backmarket.com/hc/en-us/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market',
            },
          ],
        },
        {
          title: translations.categoryGuides,
          content: [
            getFooterLink('BACK_STORIES'),
            getFooterLink('BUYING_GUIDES'),
            getFooterLink('MODELS_COMPARISON'),
            getFooterLink('GIFT_FINDER'),
          ],
        },
        {
          title: translations.categoryLaw,
          content: [
            getFooterLink('TERMS_SERVICE'),
            getFooterLink('TERMS_BUYBACK'),
            getCookiePolicyLink(),
            getCCPALink(),
            getFooterLink('DATA_PROTECTION'),
            getFooterLink(
              'ILLEGAL_CONTENT',
              'https://help.backmarket.com/hc/en-us/requests/new?ticket_form_id=12226993197084',
            ),
          ],
        },
        {
          content: [
            {
              type: 'logos',
              logos: ['bcorp', 'bbb'],
            },
          ],
        },
      ]
    default:
      return null
  }
}
